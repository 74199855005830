import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  // 首页
  {
    path: "/",
    name: "indexLink",
    component: () => import("@/views/Index/index.vue"),
  },
  // 政策法规
  {
    path: "/lawsregulations",
    name: "lawsregulationsLink",
    component: () => import("@/views/LawsRegulations/lawsregulations.vue"),
  },
  // 今日申报
  {
    path: "/declaration",
    name: "declarationLink",
    component: () => import("@/views/declaration/declaration.vue"),
  },
  // 中小企业数据库
  {
    path: "/database",
    name: "databaseLink",
    component: () => import("@/views/database/database.vue"),
  },
  // 中小企业数据库详情
  {
    path: "/databasemessage",
    name: "databasemessageLink",
    component: () => import("@/views/databasemessage/databasemessage.vue"),
  },
  // 今日申报详情
  {
    path: "/declarationdetails",
    name: "declarationdetailsLink",
    component: () =>
      import("@/views/declarationdetails/declarationdetails.vue"),
  },
  // 首页服务专员列表
  {
    path: "/commissioner",
    name: "commissionerLink",
    component: () => import("@/views/commissioner/commissioner.vue"),
  },
  // 首页服务专员详情
  {
    path: "/commissionerdetails",
    name: "commissionerdetailsLink",
    component: () =>
      import("@/views/commissionerdetails/commissionerdetails.vue"),
  },
  // 首页政策匹配
  {
    path: "/matching",
    name: "matchingLink",
    component: () => import("@/views/matching/matching.vue"),
  },
  // 政策法规详情
  {
    path: "/lawsdetails",
    name: "lawsdetailsLink",
    component: () => import("@/views/LawsDetails/lawsdetails.vue"),
  },
  // 热点动态
  {
    path: "/hotdynamic",
    name: "hotdynamicLink",
    component: () => import("@/views/HotDynamic/hotdynamic.vue"),
  },
  // 热点动态详情
  {
    path: "/hotdetails",
    name: "hotdetailsLink",
    component: () => import("@/views/HotDetails/hotdetails.vue"),
  },
  // 通知公告
  {
    path: "/announcements",
    name: "AnnouncementsLink",
    component: () => import("@/views/Announcements/announcements.vue"),
  },
  // 通知公告详情
  {
    path: "/announcedetails",
    name: "AnnounceDetailsLink",
    component: () => import("@/views/AnnounceDetails/announcedetails.vue"),
  },
  // 登录专员
  {
    path: "/sign",
    name: "signLink",
    component: () => import("@/views/sign/sign.vue"),
  },
  // 服务产品
  {
    path: "/serveProduct",
    name: "serveProductLink",
    component: () => import("@/views/serveProduct/serveProduct.vue"),
  },
  // 服务产品详情
  {
    path: "/serveDetails",
    name: "serveDetailsLink",
    component: () => import("@/views/serveDetails/serveDetails.vue"),
  },
  // 对接状态
  {
    path: "/response",
    name: "responseLink",
    component: () => import("@/views/response/response.vue"),
  },
  // 服务产品介绍
  {
    path: "/serveIntroduce",
    name: "serveIntroduceLink",
    component: () => import("@/views/serveIntroduce/serveIntroduce.vue"),
  },
  // 供方市场
  {
    path: "/feed",
    name: "feedLink",
    component: () => import("@/views/feed/feed.vue"),
  },
  // 供方详情
  {
    path: "/feedmessage",
    name: "feedmessageLink",
    component: () => import("@/views/feedmessage/feedmessage.vue"),
  },
  // 发布供方产品
  {
    path: "/releasefeed",
    name: "releasefeedLink",
    component: () => import("@/views/releasefeed/releasefeed.vue"),
  },
  // 发布采购需求产品
  {
    path: "/releasepurchase",
    name: "releasepurchaseLink",
    component: () => import("@/views/releasepurchase/releasepurchase.vue"),
  },
  // 查看采购需求产品
  {
    path: "/demandmessage",
    name: "demandmessageLink",
    component: () => import("@/views/demandmessage/demandmessage.vue"),
  },
  // 填写对接详情
  {
    path: "/docking",
    name: "dockingLink",
    component: () => import("@/views/docking/docking.vue"),
  },
  // 查看其他需求产品
  {
    path: "/orthermessage",
    name: "orthermessageLink",
    component: () => import("@/views/orthermessage/orthermessage.vue"),
  },
  // 查看其他需求产品
  {
    path: "/financingmessage",
    name: "financingmessageLink",
    component: () => import("@/views/financingmessage/financingmessage.vue"),
  },
  // 查看人才需求产品
  {
    path: "/servemessage",
    name: "servemessageLink",
    component: () => import("@/views/servemessage/servemessage.vue"),
  },
  // 查看服务需求产品
  {
    path: "/talentsmessage",
    name: "talentsmessageLink",
    component: () => import("@/views/talentsmessage/talentsmessage.vue"),
  },
  // 查看物流需求产品
  {
    path: "/logisticsmessage",
    name: "logisticsmessageLink",
    component: () => import("@/views/logisticsmessage/logisticsmessage.vue"),
  },
  // 发布融资需求
  {
    path: "/releasefinancing",
    name: "releasefinancingLink",
    component: () => import("@/views/releasefinancing/releasefinancing.vue"),
  },
  // 发布物流需求
  {
    path: "/releaselogistics",
    name: "releaselogisticsLink",
    component: () => import("@/views/releaselogistics/releaselogistics.vue"),
  },
  // 发布服务需求
  {
    path: "/releasedserve",
    name: "releasedserveLink",
    component: () => import("@/views/releasedserve/releasedserve.vue"),
  },
  // 发布人才需求
  {
    path: "/releasedtalents",
    name: "releasedtalentsLink",
    component: () => import("@/views/releasedtalents/releasedtalents.vue"),
  },
  // 发布人才需求
  {
    path: "/releasedorther",
    name: "releasedortherLink",
    component: () => import("@/views/releasedorther/releasedorther.vue"),
  },
  // 需方市场
  {
    path: "/demand",
    name: "demandLink",
    component: () => import("@/views/demand/demand.vue"),
  },
  // 服务机构个人中心
  {
    path: "/personalcenter",
    name: "personalcenterLink",
    component: () => import("@/views/personalcenter/personalcenter.vue"),
    children: [
      // 服务机构认领
      {
        path: "/claim",
        name: "claimLink",
        component: () => import("@/views/claim/claim.vue"),
      },
      // 我的产品
      {
        path: "/product",
        name: "productLink",
        component: () => import("@/views/product/product.vue"),
      },
      //添加产品
      {
        path: "/Addproduct",
        name: "AddproductLink",
        component: () => import("@/views/Addproduct/Addproduct.vue"),
      },
      //添加产品
      {
        path: "/Addproduct",
        name: "AddproductLink",
        component: () => import("@/views/Addproduct/Addproduct.vue"),
      },
      //添加月报
      {
        path: "/Addmonthly",
        name: "AddmonthlyLink",
        component: () => import("@/views/Addmonthly/Addmonthly.vue"),
      },
      //添加下月服务计划
      {
        path: "/Addnextmonthly",
        name: "AddnextmonthlyLink",
        component: () => import("@/views/Addnextmonthly/Addnextmonthly.vue"),
      },
        //添加服务活动填报表
        {
          path: "/addsweveWriting",
          name: "addsweveWritingLink",
          component: () => import("@/views/addsweveWriting/addsweveWriting.vue"),
        },
         //修改服务活动填报表
         {
          path: "/upsweveWriting",
          name: "upsweveWritingLink",
          component: () => import("@/views/upsweveWriting/upsweveWriting.vue"),
        },
      //添加服务月报
      {
        path: "/servemonthly",
        name: "servemonthlyLink",
        component: () => import("@/views/servemonthly/servemonthly.vue"),
      },
      // 我的订单
      {
        path: "/order",
        name: "orderLink",
        component: () => import("@/views/order/order.vue"),
      },
      // 服务币充值
      {
        path: "/recharge",
        name: "rechargeLink",
        component: () => import("@/views/recharge/recharge.vue"),
      },
      // 服务报送
      {
        path: "/serve",
        name: "serveLink",
        component: () => import("@/views/serve/serve.vue"),
        children: [
          //下月服务计划
          {
            path: "/nextAction",
            name: "nextActionLink",
            component: () => import("@/views/nextAction/nextAction.vue"),
          },
          //服务活动月报
          {
            path: "/serviceAction",
            name: "serviceActionLink",
            component: () => import("@/views/serviceAction/serviceAction.vue"),
          },
          //基本信息月报
          {
            path: "/information",
            name: "informationLink",
            component: () => import("@/views/information/information.vue"),
          },
          //服务活动填报表
          {
            path: "/serveWriting",
            name: "serveWritingLink",
            component: () => import("@/views/serveWriting/serveWriting.vue"),
          },
        ],
      },
      // 诉求管理
      {
        path: "/regulator",
        name: "regulatorLink",
        component: () => import("@/views/regulator/regulator.vue"),
      },
      // 服务机构供方市场管理
      {
        path: "/servemarket",
        name: "servemarketLink",
        component: () => import("@/views/servemarket/servemarket.vue"),
      },
      // 服务机构需方市场管理
      {
        path: "/serveneed",
        name: "serveneedLink",
        component: () => import("@/views/serveneed/serveneed.vue"),
      },
    ],
  },

  // 服务专员个人中心
  {
    path: "/serviceSpecialist",
    name: "serviceSpecialistLink",
    component: () => import("@/views/serviceSpecialist/serviceSpecialist.vue"),
    children: [
      {
        path: "/specialistInformation",
        name: "specialistInformationLink",
        component: () =>
          import("@/views/specialistInformation/specialistInformation.vue"),
      },
      {
        path: "/specialistappeal",
        name: "specialistappealLink",
        component: () =>
          import("@/views/specialistappeal/specialistappeal.vue"),
      },
      {
        path: "/specialistorder",
        name: "specialistorderLink",
        component: () => import("@/views/specialistorder/specialistorder.vue"),
      },
      {
        path: "/servemoney",
        name: "servemoneyLink",
        component: () => import("@/views/servemoney/servemoney.vue"),
      },
      {
        path: "/feedback",
        name: "feedbackLink",
        component: () => import("@/views/feedback/feedback.vue"),
      },
      {
        path: "/addfeedback",
        name: "addfeedbackLink",
        component: () => import("@/views/addfeedback/addfeedback.vue"),
      },
    ],
  },

  // 企业个人中心
  {
    path: "/enterprise",
    name: "enterpriseLink",
    component: () => import("@/views/enterprise/enterprise.vue"),
    children: [
      {
        path: "/appeal",
        name: "appealLink",
        component: () => import("@/views/appeal/appeal.vue"),
      },
      {
        path: "/enterpriseorder",
        name: "enterpriseorderLink",
        component: () => import("@/views/enterpriseorder/enterpriseorder.vue"),
      },
      {
        path: "/enterprisedeclare",
        name: "enterprisedeclareLink",
        component: () =>
          import("@/views/enterprisedeclare/enterprisedeclare.vue"),
      },
      {
        path: "/enterpriseaccount",
        name: "enterpriseaccountLink",
        component: () =>
          import("@/views/enterpriseaccount/enterpriseaccount.vue"),
      },
      {
        //服务币管理
        path: "/servemoney",
        name: "servemoneyLink",
        component: () => import("@/views/servemoney/servemoney.vue"),
      },
      {
        //供方市场管理
        path: "/marketManagement",
        name: "marketManagementLink",
        component: () =>
          import("@/views/marketManagement/marketManagement.vue"),
      },
      {
        //需方市场管理
        path: "/purchaseManagement",
        name: "purchaseManagementLink",
        component: () =>
          import("@/views/purchaseManagement/purchaseManagement.vue"),
      },
      {
        //企业动态
        path: "/dynamic",
        name: "dynamicLink",
        component: () =>
          import("@/views/dynamic/dynamic.vue"),
      },
      {
        //企业荣誉
        path: "/honor",
        name: "honorLink",
        component: () =>
          import("@/views/honor/honor.vue"),
      },
      // {
      //   //需方市场管理
      //   demandManagement
      //   path: "/purchaseManagement",
      //   name: "purchaseManagementLink",
      //   component: () =>
      //     import("@/views/purchaseManagement/purchaseManagement.vue"),
      // },
    ],
  },
  // 服务联盟
  {
    path: "/servicealliance",
    name: "ServiceAllianceLink",
    component: () => import("@/views/ServiceAlliance/servicealliance.vue"),
  },
  // 专精特新
  {
    path: "/special",
    name: "special",
    component: () => import("@/views/special/special.vue"),
  },
  // 专家库
  {
    path: "/expertbase",
    name: "ExpertBaseLink",
    component: () => import("@/views/ExpertBase/expertbase.vue"),
  },
  // 法律服务
  {
    path: "/LegalService",
    name: "LegalServiceLink",
    component: () => import("@/views/LegalService/LegalService.vue"),
    meta: {
      breadcrumb: [
        { path: "/", name: "首页" },
        { path: "", name: "法律服务" },
      ],
    },
  },
  // 服务机构列表
  {
    path: "/organizationDetails/:id(\\d+)?",
    name: "organizationDetailsList",
    component: () =>
      import("@/views/organizationDetails/organizationDetails.vue"),
    meta: {
      breadcrumb: [
        { path: "/", name: "首页" },
        { path: "/LegalService", name: "法律服务" },
        { path: "", name: "服务机构列表" },
      ],
    },
  },
  // 服务机构详情
  {
    path: "/particulars/:id(\\d+)?",
    name: "particularsList",
    component: () => import("@/views/particulars/particulars.vue"),
    meta: {
      breadcrumb: [
        { path: "/", name: "首页" },
        { path: "/LegalService", name: "法律服务" },
        { path: "/organizationDetails", name: "服务机构列表" },
        { path: "", name: "详情" },
      ],
    },
  },
  // 律师列表
  {
    path: "/lawyers/:id(\\d+)?",
    name: "lawyersList",
    component: () => import("@/views/lawyers/lawyers.vue"),
    meta: {
      breadcrumb: [
        { path: "/", name: "首页" },
        { path: "/LegalService", name: "法律服务" },
        { path: "", name: "律师列表" },
      ],
    },
  },
  // 律师详情
  {
    path: "/lawyersDetails/:id(\\d+)?",
    name: "lawyersDetailsList",
    component: () => import("@/views/lawyersDetails/lawyersDetails.vue"),
    meta: {
      breadcrumb: [
        { path: "/", name: "首页" },
        { path: "/LegalService", name: "法律服务" },
        { path: "/lawyers", name: "律师列表" },
        { path: "", name: "详情" },
      ],
    },
  },
  // 产品列表
  {
    path: "/products/:id(\\d+)?",
    name: "productsList",
    component: () => import("@/views/products/products.vue"),
    meta: {
      breadcrumb: [
        { path: "/", name: "首页" },
        { path: "/LegalService", name: "法律服务" },
        { path: "", name: "列表" },
      ],
    },
  },
  {
    path: "/productsDetails/:id(\\d+)?",
    name: "productsDetailsList",
    component: () => import("@/views/productsDetails/productsDetails.vue"),
    meta: {
      breadcrumb: [
        { path: "/", name: "首页" },
        { path: "/LegalService", name: "法律服务" },
        { path: "/products", name: "产品列表" },
        { path: "", name: "详情" },
      ],
    },
  },
  {
    path: "/typicalDaseDetails/:id(\\d+)?",
    name: "typicalDaseDetailsList",
    component: () =>
      import("@/views/typicalDaseDetails/typicalDaseDetails.vue"),
    meta: {
      breadcrumb: [
        { path: "/", name: "首页" },
        { path: "/LegalService", name: "法律服务" },
        { path: "", name: "详情" },
      ],
    },
  },
  {
    path: "/lawDetails/:id(\\d+)?",
    name: "typicalDaseDetailsList",
    component: () => import("@/views/lawDetails/lawDetails.vue"),
    meta: {
      breadcrumb: [
        { path: "/", name: "首页" },
        { path: "/LegalService", name: "法律服务" },
        { path: "", name: "详情" },
      ],
    },
  },
];

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;

import axios from "axios";
import router from "../router";
import { Message } from "element-ui";

// 请求拦截
axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
axios.interceptors.response.use(
  (response) => {
    if(  response.data.status == 403 || response.data.status == 401 ){
      console.log(localStorage.eleToken ,'localStorage.eleToken ')
      logout.get('/logout?token=' + localStorage.eleToken )
      .then(function(res) {
        console.log(res,'reshttp')
        
           
            // location.reload();
            
          setTimeout(() => {
            router.replace("/login");
            localStorage.clear();
          }, 1000);

      })
      .catch(function(error) {
        console.log(error)
      })
    
  

     

    }
    if (
      response.data.status == 402 ||
      response.data.status == 405
    ) {
      
      Message({
        showClose: true,
        type: "error",
        duration: 2000,
        message: response.data.msg,
        onClose: function () {
          localStorage.clear();

          setTimeout(() => {
            router.replace("/login");
          }, 1000);
        },
      });



      return;
    }

    if (response.data.code) {
      if (response.data.code == 0) {
        Message({
          showClose: true,
          type: "error",
          duration: 2000,
          message: response.data.msg,
        });
        return;
      }
    } else {
      if (response.data.code == 0) {
        return response.data;
      }

      if (response.data.status != 1) {
        Message({
          showClose: true,
          type: "error",
          duration: 2000,
          message: response.data.msg,
        });
        return response.data;
      }
    }

    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// logout(){
//   let data = {
//     token: localStorage.eleToken,
//   };
//   console.log( this.$get,' this.$get');
//   this.$get("/logout", data).then((res) => {
//     console.log(res,'reshttp')
//     localStorage.clear();
//     location.reload();
//     this.$message({
//       type: "success",
//       message: "已退出",
//     });
  
//   });
// }

const logout = axios.create({
  baseURL: 'https://www.smeqh.cn/v1', // 设置接口域名，根据实际情况设置
  timeout: 5000, // 设置超时时间
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
})




export default axios;

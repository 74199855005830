import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./http";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import BaiduMap from "vue-baidu-map";

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

// 引用fullpage 插件
import "fullpage.js/vendors/scrolloverflow";
import VueFullpage from "vue-fullpage.js";
import $ from 'jquery'
Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use($);

Vue.use(VueFullpage);

Vue.use(BaiduMap, {
  ak: "https://api.map.baidu.com/api?v=2.0&ak=IsyxI1I7oHcZVE4slZD1tR2XcLt6vD8I",
  // ak:"https://webapi.amap.com/maps?v=1.4.15&key=71a8faa19f9d7fc95925527267abfb26"
});

// 引入vue-amap
import VUEAMap from "vue-amap";
Vue.use(VUEAMap);

// 初始化vue-amap
VUEAMap.initAMapApiLoader({
  // 申请的高德key
  key: "71a8faa19f9d7fc95925527267abfb26",
  // 插件集合
  plugin: [""],
});
//高德的安全密钥
window._AMapSecurityConfig = {
	securityJsCode:"8d94ae91b13d61b05b82978575ce3011" // "安全密钥",
}
// //富文本编辑器
// import VueQuillEditor, { Quill } from "vue-quill-editor";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
// import imageResize from "quill-image-resize-module"; // 调整大小组件。
// import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件。
// Quill.register("modules/imageResize", imageResize);
// Quill.register("modules/imageDrop", ImageDrop);
// Vue.use(VueQuillEditor);

Vue.prototype.baseUrl = "https://www.smeqh.cn/v1"; //线上
// Vue.prototype.baseUrl = "http://jxx.zhenchangkj.com/v1"; //测试

// Vue.prototype.baseUrl = "http://jxx.zhenchangkj.com/v1";

//get请求
http: Vue.prototype.$get = function (api, data) {
  if (data === undefined) {
    data = {};
  }

  let ox = axios({
    baseURL: this.baseUrl,
    url: api,
    method: "get",
    params: data,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return ox;
};
//post请求

Vue.prototype.$post = function (api, data) {
  if (data === undefined) {
    data = {};
  }

  let ox = axios({
    baseURL: this.baseUrl,
    url: api,
    method: "post",
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });

  return ox;
};

//手机号验证
Vue.prototype.$regPhone =
  /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

//邮箱验证
Vue.prototype.$regEmail =
  /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
// /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/

//密码验证
Vue.prototype.$regPass = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;

// vue跳转新页面后回到顶部
router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0;

  // firefox
  document.documentElement.scrollTop = 0;

  // safari
  window.pageYOffset = 0;

  next();
});

//时间格式化
Vue.prototype.formatDateY = function (t) {
  t = t || Date.now();
  let time = new Date(Number(t));
  let str = time.getFullYear();
  str += "-";
  str +=
    time.getMonth() < 9 ? "0" + (time.getMonth() + 1) : time.getMonth() + 1;
  str += "-";
  str += time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
  str += " ";
  str += time.getHours();
  str += ":";
  str += time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
  return str;
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
